import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import GuideArticlesRoll from "../../components/GuideArticlesRoll"
import { DownloadBanner } from "../../components/DownloadBanner"

const Pricing = ({ path }) => {
  return (
    <Layout loadMap={false} hasSubNav={true} navId="lostALovedOne">
      <SEO
        path={path}
        title="Ultimate Guide On What To Do When Someone Dies"
        description="A guide to help you understand what steps you need to take when a loved one dies in Australia."
      />
      <section className="bg-white section-gap">
        <div className="container">
          <h1 className="mb-3 font-bold">
            Ultimate Guide On What To Do When Someone Dies{" "}
          </h1>

          <section className="center-container">
            <iframe
              className="video-general-iframe"
              src="https://www.youtube.com/embed/PlrryU1HRjA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </section>
          <p className="mb-lg-7">
            We know how overwhelming it can be when someone dies. Going through
            the grieving process is hard enough, and then there’s all the
            administrative work that needs to be done.
            <br /> <br />
            There is so much information online, and it’s hard to know what to
            trust. So we created a guide where everything is in one spot,
            helping you understand what steps you need to take when a loved one
            dies.
          </p>
        </div>
        <GuideArticlesRoll guideMain="deceased" />
      </section>
      <DownloadBanner />
    </Layout>
  )
}

export default Pricing
